import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {QuerySortOperator} from '@nestjsx/crud-request/lib/types/request-query.types';
import {RequestQueryBuilder} from '@nestjsx/crud-request';

import {Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';

import {Page} from '../models/page';

export class GenericApiService<DTO, VIEW> {
	protected baseUrl: string;

	constructor(public readonly http: HttpClient, public readonly toastr: ToastrService) {
	}

	getAll(sortField: string, sortDirection: QuerySortOperator, options?: any): Observable<VIEW[]> {
		let query = this.buildRequestQueryBuilder(sortField, sortDirection, 0, 0, options).query(
			false,
		);
		if (query && query.length > 0) {
			query = '?' + query;
		}

		return this.http.get<VIEW[]>(`${this.baseUrl}${query}`).pipe(
			retry(3),
			catchError((error) => this.handleError(error)),
			map((data) => data?.map((i) => this.mapResultItem(i))),
		);
	}

	getPage(
		sortField: string,
		sortDirection: QuerySortOperator,
		page: number,
		offset: number,
		options: any,
	): Observable<Page<VIEW>> {
		const query = this.buildRequestQueryBuilder(
			sortField,
			sortDirection,
			page,
			offset,
			options,
		).query(false);

		return this.http.get<Page<VIEW>>(`${this.baseUrl}?${query}`).pipe(
			retry(3),
			catchError((error) => this.handleError(error)),
			map((p) => {
				if (p && p.data) {
					p.data = p.data.map((i) => this.mapResultItem(i));
				}
				return p;
			}),
		);
	}

	getById(id: number, query?: string): Observable<VIEW> {
		query = query && query.length > 0 ? '?' + query : '';
		return this.http.get<VIEW>(`${this.baseUrl}/${id}${query}`).pipe(
			retry(3),
			catchError((error) => this.handleError(error)),
			map((data) => this.mapResultItem(data)),
		);
	}

	create(dto: DTO): Observable<VIEW> {
		return this.http.post<VIEW>(this.baseUrl, dto).pipe(
			map((data) => {
				this.showSuccess('Create', 'Created');
				return data;
			}),
			catchError((error) => this.handleError(error)),
			map((data) => this.mapResultItem(data)),
		);
	}

	update(id: number, dto: DTO): Observable<VIEW> {
		return this.http.patch<VIEW>(`${this.baseUrl}/${id}`, dto).pipe(
			map((data) => {
				this.showSuccess('Update', 'Updated');
				return data;
			}),
			catchError((error) => this.handleError(error)),
			map((data) => this.mapResultItem(data)),
		);
	}

	delete(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${id}`).pipe(
			retry(3),
			catchError((error) => this.handleError(error)),
		);
	}

	protected buildRequestQueryBuilder(
		sortField: string,
		sortDirection: QuerySortOperator,
		page: number,
		offset: number,
		options: any,
	): RequestQueryBuilder {
		const requestQueryBuilder = RequestQueryBuilder.create();
		if (options && options.fields) {
			requestQueryBuilder.select(options.fields);
		}
		if (sortField && sortField.length > 0) {
			requestQueryBuilder.sortBy({field: sortField, order: sortDirection || 'ASC'});
		}
		if (page > 0) {
			requestQueryBuilder.setPage(page);
		}
		if (offset > 0) {
			requestQueryBuilder.setLimit(offset);
		}
		return requestQueryBuilder;
	}

	protected mapResultItem(item: VIEW): VIEW {
		return item;
	}

	protected showSuccess(title: string, message: string) {
		this.toastr.success(message, title);
	}

	protected handleError(error: HttpErrorResponse): Observable<never> {
		// Todo -> Send the error to remote logging infrastructure
		console.error(error); // log to console instead

		/* debugger;
		 const message =
			 error.error instanceof ErrorEvent
				 ? error.error.message
				 : `{error code: ${error.status}, body: "${error.message}"}`;*/

		// Server or connection error happened
		if (!navigator.onLine || error.status === 0) {
			// Handle offline error
			this.toastr.error('Connection server error!', 'Error!');
		} else {
			if (error.error && error.error.code) {
				console.debug(error.error);
			} else {
				let msg = error.error && error.error.message;
				if (msg && msg.join) {
					msg = msg.join('\n');
				}
				this.toastr.error(
					`${error.status} - ${this.prepareErrorMessage(msg || error.message)}`,
					'Error!',
				);
			}
		}
		return throwError(error);
	}

	private prepareErrorMessage(msg: string | Object | Array<any>) {
		if (!msg) {
			return 'Unknown error';
		}
		if (typeof msg === 'string') {
			return msg;
		}
		let arr = [];
		if (!Array.isArray(msg)) {
			arr = [msg];
		} else {
			arr = this.errorMsgArrayToString(msg);
		}
		return arr.map((item) => this.errorMsgObjectToString(item)).join(';');
	}

	private errorMsgArrayToString(errors): string[] {
		if (!(errors && errors.length)) {
			return [];
		}
		let result = [];
		for (const error of errors) {
			if (error.constraints) {
				result.push(error);
			}
			result = result.concat(this.errorMsgArrayToString(error.children));
		}
		return result;
	}

	private errorMsgObjectToString(msg: Object) {
		const err = msg['constraints'];
		if (err) {
			const keys = Object.keys(err);
			if (keys && keys.length) {
				return err[keys[0]];
			}
			return err.toString();
		}
		return '';
	}
}
