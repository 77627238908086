<!--Login Page Starts-->
<section id='login'>
    <div class='row auth-height full-height-vh m-0'>
        <div class='col-12 d-flex align-items-center justify-content-center'>
            <div class='card overflow-hidden'>
                <div class='card-content'>
                    <div class='card-body auth-img'>
                        <div class='row m-0'>
                            <div
                                    class='col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3'>
                                <img src='assets/img/gallery/sicame-login.png' alt='' class='img-fluid' width='300'
                                     height='300'>
                            </div>
                            <div class='col-lg-6 col-12 px-4 py-3'>
                                <h4 class='mb-2 card-title'>Login</h4>
                                <p>Welcome back, please login to your account.</p>
                                <ngb-alert type='light-danger' class='mb-2' *ngIf='isLoginFailed'
                                           (close)='isLoginFailed = false'>
                                    <p class='mb-0'>Login failed!</p>
                                </ngb-alert>
                                <form [formGroup]='loginForm'>
                                    <div class='form-group'>
                                        <input type='text' formControlName='username' class='form-control'
                                               placeholder='Username'
                                               [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                                               required>
                                        <div
                                                *ngIf='loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)'
                                                class='help-block mt-1 text-danger'><i
                                                class='ft-alert-circle align-middle'></i> This is
                                            required
                                        </div>
                                    </div>
                                    <div class='form-group'>
                                        <input type='password' formControlName='password' class='form-control'
                                               placeholder='Password'
                                               [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                                               required>
                                        <div *ngIf='loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)' class='help-block mt-1 text-danger'>
                                            <i class='ft-alert-circle align-middle'></i> The password is required
                                        </div>
                                        <div class='d-flex justify-content-between align-items-center small'>
                                            <a href="mailto:dampingsoftware@sicamegroup.com?subject=Forgot Password request from NAM&body=This e-mail must be sent from the account user e-mail to be correctly processed%0AName:%0ASurname:%0AEmail:%0ACompany:%0AMobile:%0ATelephone:%0A%0ASicame will contact you by phone before resetting account password.">
                                                Forgot password</a>
                                        </div>
                                    </div>
                                    <div class='d-sm-flex justify-content-between mb-3 font-small-2'>
                                        <div class='remember-me mb-2 mb-sm-0'>
                                            <div class='checkbox auth-checkbox'>
                                                <input type='checkbox' formControlName='rememberMe' class='form-control'
                                                       id='rememberMe'>
                                                <label for='rememberMe'><span
                                                        class='font-small-2 mb-3 font-weight-normal'>Remember Me</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='d-flex justify-content-between flex-sm-row flex-column'>
                                        <label class='mb-2 mb-sm-0'>&nbsp;</label>
                                        <button type="submit" class='btn btn-primary' (click)='onSubmit()'>Login
                                        </button>
                                    </div>
                                </form>
                                <hr>
                                <div class='d-flex justify-content-between align-items-center small'>
                                    <a href="mailto:dampingsoftware@sicamegroup.com?subject=Registration request from NAM&body=Registration request%0AName:%0ASurname:%0AEmail:%0ACompany:%0AAddress:%0ACountry:%0APosition:%0AMobile:%0ATelephone:%0A%0ASicame will contact you by phone before creating an account.">
                                        Ask for registration</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->
