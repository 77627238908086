import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanDeactivateComponent } from './can-deactivate.component';
import { ConfirmCancelButton } from '../services/sweet-alerts';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
    canDeactivate(component: CanDeactivateComponent): Promise<boolean> {
        if (!component.canDeactivate()) {
            return ConfirmCancelButton(
                'You have unsaved changes! If you leave, your changes will be lost.',
            ).then(
                (result) => {
                    return !(result && result.dismiss);
                },
                // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
                (dismiss) => {
                    return false;
                },
            );
        }
        return Promise.resolve(true);
    }
}
