import {Routes} from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
	// {
	// 	path: 'dashboard',
	// 	loadChildren: () => import('../../dashboard/dashboard.module').then((m) => m.DashboardModule),
	// },
	{
		path: 'projects',
		loadChildren: () =>
			import('../../projects/projects.module').then((m) => m.ProjectsModule),
	},
	{
		path: 'access',
		loadChildren: () => import('../../access/access.module').then((m) => m.AccessModule),
	},
	{
		path: 'dictionaries',
		loadChildren: () =>
			import('../../dictionaries/dictionaries.module').then((m) => m.DictionariesModule),
	},
];
