import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
    constructor(private http: HttpClient) {}

    postImage(
        url: string,
        image: File,
        partName: string = 'image',
        customFormData?: { [name: string]: any },
    ): Observable<Object> {
        if (!url || url === '') {
            throw new Error('Url is not set! Please set it before doing queries');
        }

        // add custom form data
        const formData = new FormData();
        // tslint:disable-next-line:forin
        for (const key in customFormData || []) {
            formData.append(key, customFormData[key]);
        }
        formData.append(partName, image);
        return this.http.post(url, formData);
    }

    deleteImage(url: string, name: string): Observable<Object> {
        return this.http.delete(`${url}/${name}`);
    }

    copyImage(url: string, name: string): Observable<any> {
        return this.http.put(`${url}/${name}`, null);
    }
}
