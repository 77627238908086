import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TitleService} from './shared/services/title.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	subscription: Subscription;

	constructor(
		private router: Router,
		private titleService: TitleService,
	) {
	}

	ngOnInit() {
		this.subscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => window.scrollTo(0, 0));
		this.titleService.init();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
