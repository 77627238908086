import {RouteInfo} from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
	{
		path: null,
		title: 'CURRENT_USER',
		icon: 'ft-user',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		submenu: [],
		permissions: [],
	},
	{
		path: '/projects',
		title: 'projects.menu.title',
		icon: 'ft-home',
		class: '',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		permissions: ['project_read'],
		submenu: [],
	},
	{
		path: '',
		title: 'access.menu.title',
		icon: 'ft-box',
		class: 'dropdown nav-item has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		permissions: ['user_read', 'role_read'],
		submenu: [
			{
				path: '/access/users',
				title: 'users.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['user_read'],
			},
			{
				path: '/access/roles',
				title: 'roles.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['role_read'],
			},
		],
	},
	{
		path: '',
		title: 'dictionaries.menu.title',
		icon: 'ft-box',
		class: 'dropdown nav-item has-sub',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		permissions: ['conductor_create', 'damper_create', 'suspension-clamp_create', 'tension-clamp_create'],
		submenu: [
			{
				path: '/dictionaries/conductors',
				title: 'conductors.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['conductor_create'],
			},
			{
				path: '/dictionaries/dampers',
				title: 'dampers.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['damper_create'],
			},
			{
				path: '/dictionaries/tension-clamps',
				title: 'tensionClamps.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['tension-clamp_create'],
			},
			{
				path: '/dictionaries/suspension-clamps',
				title: 'suspensionClamps.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
				permissions: ['suspension-clamp_create'],
			},
		],
	},
	{
		path: '/auth/login',
		title: 'Logout',
		icon: 'ft-log-out',
		class: 'nav-item',
		badge: '',
		badgeClass: '',
		isExternalLink: false,
		submenu: [],
		permissions: [],
	},
];
