import Swal, {SweetAlertIcon} from 'sweetalert2';

// Confirm & Cancel Button
export function ConfirmCancelButton(
	title: string = 'Are you sure?',
	text: string = '',
	html: string = '',
	icon: SweetAlertIcon = 'question') {
	return Swal.fire({
		title: title,
		text: text,
		html: html,
		icon: icon,
		showCancelButton: true,
		confirmButtonColor: '#0CC27E',
		cancelButtonColor: '#FF586B',
		confirmButtonText: 'Yes!',
		cancelButtonText: 'No!',
		buttonsStyling: false,
		customClass: {
			cancelButton: 'btn btn-danger btn-raised',
			confirmButton: 'btn btn-success btn-raised mr-5'
		}
	});
}

// Cancel Button
export function CancelButton(
	title: string = 'Info',
	text: string = '',
	html: string = '',
	icon: SweetAlertIcon = 'info') {
	return Swal.fire({
		title: title,
		text: text,
		html: html,
		icon: icon,
		showCancelButton: true,
		showConfirmButton: false,
		// cancelButtonColor: '#0CC27E',
		cancelButtonText: 'Close',
		// buttonsStyling: false,
		customClass: {
			cancelButton: 'btn btn-secondary btn-raised'
		}
	});
}

export function SelectValue(
	inputOptions,
	inputPlaceholder,
	title: string = 'Info',
	icon: SweetAlertIcon = 'info') {
	return Swal.fire({
		title: title,
		input: 'select',
		inputOptions: inputOptions,
		inputPlaceholder: inputPlaceholder,
		showCancelButton: true,
		/*inputValidator: (value) => {
			return new Promise((resolve) => {
				if (value === 'oranges') {
					resolve()
				} else {
					resolve('You need to select oranges :)')
				}
			})
		}*/
	});
}

export function SelectFile(
	inputAttributes,
	title: string = 'Select file') {
	return Swal.fire({
		title: title,
		input: 'file',
		inputAttributes: inputAttributes,
		showCancelButton: true,
	});
}


export function InfoModal(title: string) {
	return Swal.fire(title);
}
