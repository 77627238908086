<nav class='navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}'
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
    <div class='container-fluid navbar-wrapper'>
        <div class='navbar-header d-flex'>
            <div
                    class='navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center'
                    data-toggle='collapse' (click)='toggleSidebar()'>
                <i class='ft-menu font-medium-3'></i>
            </div>
        </div>
    </div>
</nav>
