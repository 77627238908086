import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
    {
        path: '/projects',
        title: 'projects.menu.title',
        icon: 'ft-home',
        class: 'dropdown nav-item',
        isExternalLink: false,
        submenu: [],
    },
];
