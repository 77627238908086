import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutService} from '../services/layout.service';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {UntypedFormControl} from '@angular/forms';
import {LISTITEMS} from '../data/template-search';
import {Router} from '@angular/router';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	currentLang = 'en';
	selectedLanguageText = 'general.languages.en.title';
	selectedLanguageFlag = './assets/img/flags/en.png';
	toggleClass = 'ft-maximize';
	placement = 'bottom-right';
	logoUrl = 'assets/img/sicame-logo-white.png';
	menuPosition = 'Side';
	isSmallScreen = false;
	transparentBGClass = '';
	hideSidebar = true;
	public isCollapsed = true;
	layoutSub: Subscription;
	configSub: Subscription;
	@Output()
	toggleHideSidebar = new EventEmitter<Object>();
	listItems = [];
	control = new UntypedFormControl();
	public config: any = {};
	protected innerWidth: any;

	constructor(
		public translate: TranslateService,
		private layoutService: LayoutService,
		private router: Router,
		private configService: ConfigService,
		private cdr: ChangeDetectorRef,
	) {
		const browserLang: string = translate.getBrowserLang();
		// translate.use(browserLang.match(/en|it/) ? browserLang : 'en');
		translate.use('en');
		this.currentLang = translate.currentLang;
		this.selectedLanguageText = `general.languages.${this.currentLang}.title`;
		this.selectedLanguageFlag = `./assets/img/flags/${this.currentLang}.png`;
		this.config = this.configService.templateConf;
		this.innerWidth = window.innerWidth;

		this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
			this.hideSidebar = !isShow;
		});
	}

	ngOnInit() {
		this.listItems = LISTITEMS;
		this.isSmallScreen = this.innerWidth < 1200;
	}

	ngAfterViewInit() {
		this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
			if (templateConf) {
				this.config = templateConf;
			}
			this.loadLayout();
			this.cdr.markForCheck();
		});
	}

	ngOnDestroy() {
		if (this.layoutSub) {
			this.layoutSub.unsubscribe();
		}
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.innerWidth = event.target.innerWidth;
		this.isSmallScreen = this.innerWidth < 1200;
	}

	loadLayout() {
		if (
			this.config.layout.menuPosition &&
			this.config.layout.menuPosition.toString().trim() !== ''
		) {
			this.menuPosition = this.config.layout.menuPosition;
		}

		if (this.config.layout.variant === 'Light') {
			this.logoUrl = 'assets/img/sicame-logo.png';
		} else {
			this.logoUrl = 'assets/img/sicame-logo-white.png';
		}

		if (this.config.layout.variant === 'Transparent') {
			this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
		} else {
			this.transparentBGClass = '';
		}
	}

	ChangeLanguage(language: string) {
		this.translate.use(language);

		if (language === 'en') {
			this.selectedLanguageText = 'general.languages.en.title';
			this.selectedLanguageFlag = './assets/img/flags/en.png';
		} else if (language === 'it') {
			this.selectedLanguageText = 'general.languages.it.title';
			this.selectedLanguageFlag = './assets/img/flags/it.png';
		}
	}

	ToggleClass() {
		if (this.toggleClass === 'ft-maximize') {
			this.toggleClass = 'ft-minimize';
		} else {
			this.toggleClass = 'ft-maximize';
		}
	}

	toggleSidebar() {
		this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
	}
}
