import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { RouteInfo } from './vertical-menu.metadata';
import { AuthService } from '../../auth/services/auth.service';
import { MeService } from '../../auth/services/me.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './vertical-menu.component.html',
    animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    level = 0;
    logoUrl = 'assets/img/sicame-logo-white.png';
    public config: any = {};
    protected innerWidth: any;
    layoutSub: Subscription;
    configSub: Subscription;
    subUserLogin: Subscription;
    perfectScrollbarEnable = true;
    collapseSidebar = false;
    resizeTimeout;

    varticalRoutes: RouteInfo[] = [];

    constructor(
        private router: Router,
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private deviceService: DeviceDetectorService,
        private rolesService: NgxRolesService,
        private permissionsService: NgxPermissionsService,
        private authService: AuthService,
        private meService: MeService,
    ) {
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;
        this.isTouchDevice();
    }

    ngOnInit() {
        this.menuItems = this.varticalRoutes;
    }

    ngAfterViewInit() {
        this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
            if (templateConf) {
                this.config = templateConf;
            }
            this.loadLayout();
            this.cdr.markForCheck();
        });

        this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe((collapse) => {
            if (this.config.layout.menuPosition === 'Side') {
                this.collapseSidebar = collapse;
            }
        });

        this.subUserLogin = this.authService.loginNotification().subscribe(() => {
            this.varticalRoutes = [];
            this.loadLayout();
            this.cdr.markForCheck();
            /*if (this.authService.isLoggedIn()) {
                this.meService.getProfile().subscribe((user) => {
                    this.rolesService.flushRolesAndPermissions();
                    this.rolesService.addRoleWithPermissions(user.role, user.grants);

                    this.varticalRoutes = [];
                    this.loadLayout();
                    this.cdr.markForCheck();
                });
            } else {
                this.rolesService.flushRolesAndPermissions();

                this.varticalRoutes = [];
                this.loadLayout();
                this.cdr.markForCheck();
            }*/
        });
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(
            (() => {
                this.innerWidth = event.target.innerWidth;
                this.loadLayout();
            }).bind(this),
            500,
        );
    }

    loadLayout() {
        if (this.config.layout.menuPosition === 'Top') {
            // Horizontal Menu
            if (this.innerWidth < 1200) {
                // Screen size < 1200
                this.menuItems = HROUTES;
            }
        } else if (this.config.layout.menuPosition === 'Side') {
            // Vertical Menu{
            this.loadRoutes().then((data) => (this.menuItems = data));
        }

        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/sicame-logo.png';
        } else {
            this.logoUrl = 'assets/img/sicame-logo-white.png';
        }

        this.collapseSidebar = !!this.config.layout.sidebar.collapsed;
    }

    toggleSidebar() {
        const conf = this.config;
        conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
        this.configService.applyTemplateConfigChange({ layout: conf.layout });

        setTimeout(() => {
            this.fireRefreshEventOnWindow();
        }, 300);
    }

    fireRefreshEventOnWindow = function () {
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('resize', true, false);
        window.dispatchEvent(evt);
    };

    CloseSidebar() {
        this.layoutService.toggleSidebarSmallScreen(false);
    }

    isTouchDevice() {
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();

        this.perfectScrollbarEnable = !(isMobile || isTablet);
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
        if (this.subUserLogin) {
            this.subUserLogin.unsubscribe();
        }
    }

    private async loadRoutes() {
        if (this.varticalRoutes && this.varticalRoutes.length > 0) {
            return this.varticalRoutes;
        }
        const asyncFilter = async (arr, predicate) => {
            const results = await Promise.all(arr.map(predicate));
            return arr.filter((_v, index) => results[index]);
        };

        const filterRoutes = await asyncFilter(ROUTES, async (r) => {
            return !r.permissions || (await this.permissionsService.hasPermission(r.permissions));
        });

        for (let index = 0; index < filterRoutes.length; index++) {
            const item = filterRoutes[index];
            if (item.submenu && item.submenu.length) {
                item.submenu = await asyncFilter(item.submenu, async (r) => {
                    return (
                        !r.permissions ||
                        (await this.permissionsService.hasPermission(r.permissions))
                    );
                });
            }
            if(item.title === 'CURRENT_USER'){
                item.title =  this.getUserName();
            }
        }
        this.varticalRoutes = [...filterRoutes];
        return this.varticalRoutes;
    }

    private getUserName() {
        const str = sessionStorage.getItem('uv');
        if (!(str && str.length)) {
            return 'User';
        }
        const user = JSON.parse(str);
        return user && `${user.firstName} ${user.lastName}` || 'User';
    }
}
