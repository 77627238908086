import { Injectable } from '@angular/core';

import { map, filter, mergeMap } from 'rxjs/operators';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

const APP_TITLE = 'NAM';
const SEPARATOR = ' > ';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    static ucFirst(text: string) {
        if (!text) {
            return text;
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
    ) {}

    init() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                map((route) => route.snapshot),
                map((snapshot) => {
                    if (snapshot.data.title) {
                        return this.translateService.get(snapshot.data.title).pipe(
                            map((title) => {
                                // console.log(title);
                                if (snapshot.paramMap.get('id') !== null) {
                                    return title + SEPARATOR + snapshot.paramMap.get('id');
                                }
                                return title;
                            })
                        );
                    } else {
                        // If not, we do a little magic on the url to create an approximation
                        return this.router.url.split('/').reduce((acc, frag) => {
                            if (acc && frag) {
                                acc += SEPARATOR;
                            }
                            return acc + TitleService.ucFirst(frag);
                        });
                    }
                }),
                mergeMap((title) => {
                     return title;
                }),
            )
            .subscribe((pathString) => this.titleService.setTitle(`${pathString} - ${APP_TITLE}`));
    }
}
