import { Routes } from '@angular/router';
import { AuthComponent } from '../../auth/components/auth.component';
import { AUTH_ROUTES } from '../../auth/auth-routing.module';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        children: AUTH_ROUTES,
    },
    {
        path: 'pages',
        loadChildren: () =>
            import('../../pages/content-pages/content-pages.module').then(
                (m) => m.ContentPagesModule,
            ),
    },
];
