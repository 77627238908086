import { Component } from '@angular/core';

@Component({
    selector: 'app-auth',
    template: `
        <div class='wrapper'>
            <div class='content-wrapper'>
                <div class='container-fluid'>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>`,
})

export class AuthComponent {

}
