import {Injectable} from '@angular/core';
import {TranslateLoader} from '@ngx-translate/core';

import {Observable, of} from 'rxjs';

import {ConfigService} from './config.service';


@Injectable({
	providedIn: 'root',
})
export class TranslateLoaderService implements TranslateLoader {

	constructor(private configService: ConfigService) {
	}

	/**
	 * Gets the translations from the server
	 */
	public getTranslation(lang: string): Observable<Object> {
		return this.configService.getTranslate(lang);
	}
}
