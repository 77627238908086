import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {TokenStorageService} from '../../../auth/services/token-storage.service';
import {GenericApiService} from '../../../base/services/generic-api.service';

import {ResourceView} from '../../../../../../api/src/resources/responses/resource.view';

@Injectable()
export class FileService extends GenericApiService<ResourceView, ResourceView> {

	constructor(public readonly http: HttpClient,
				public readonly toastr: ToastrService,
				private tokenService: TokenStorageService) {
		super(http, toastr);
		this.baseUrl = `${environment.API_BASE_URL}/api/v1/resources`;
	}

	upload(file: File, partName: string = 'file', customFormData?: { [name: string]: any }): Observable<ResourceView> {
		// add custom form data
		const formData = new FormData();
		// tslint:disable-next-line:forin
		for (const key in customFormData || []) {
			formData.append(key, customFormData[key]);
		}
		formData.append(partName, file);
		return this.http.post<ResourceView>(this.baseUrl, formData)
			.pipe(
				catchError((error) => this.handleError(error)),
				map((data) => this.mapResultItem(data)),
			);
	}

	/**
	 * download file from server
	 * @param {number} id - file identifier
	 */
	download(id: number) {
		window.open(`${this.baseUrl}/${id}/content?auth_token=${this.tokenService.getToken()}`);
	}
}
