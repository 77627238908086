import {Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';

import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {TokenStorageService} from './token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router, private tokenStorageService: TokenStorageService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let authReq = req;
		if (this.tokenStorageService.getToken() != null) {
			authReq = req.clone({
				headers: req.headers.set(
					TOKEN_HEADER_KEY,
					'Bearer ' + this.tokenStorageService.getToken(),
				),
			});
		}

		return next.handle(authReq).pipe(
			tap(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						// do stuff with response if you want
					}
				},
				(err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401) {
							this.router.navigate(['auth/login']);
						}
					}
				},
			),
		);
	}
}
