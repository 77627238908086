import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuard } from './auth/services/auth.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: Full_ROUTES,
        canActivate: [AuthGuard],
    },
    {
        path: '',
        component: ContentLayoutComponent,
        children: CONTENT_ROUTES,
    },
    {
        path: '**',
        redirectTo: 'pages/error',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
