import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, retry} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfigService {
	public recaptchaSiteKey: string | null = null;
	public languages: Set<string> | null = null;

	constructor(public readonly http: HttpClient) {
	}

	public async loadConfig(): Promise<void> {
		const config = await this.fetchConfig().toPromise();
		this.recaptchaSiteKey = config?.recaptcha?.siteKey;
		this.languages = config?.i18n?.languages;
	}

	public getTranslate(lang: string): Observable<any> {
		return this.http.get(`${environment.API_BASE_URL}/api/v1/configs/i18n/${lang}`).pipe(
			retry(3),
			map((p: any) => {
				return p && p.data || p;
			}),
		);
	}

	private fetchConfig() {
		return this.http.get(`${environment.API_BASE_URL}/api/v1/configs`).pipe(
			retry(3),
			map((p: any) => {
				return p && p.data || p;
			}),
		);
	}
}
