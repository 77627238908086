import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {NgxPermissionsModule} from 'ngx-permissions';
import {InputMaskModule} from '@ngneat/input-mask';
import {RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';

import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {AuthModule} from './auth/auth.module';
import {ConfigService} from './base/services/config.service';
import {TranslateLoaderService} from './base/services/translate-loader.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};

export function createTranslateLoader(config: ConfigService) {
	return new TranslateLoaderService(config);
}

function appLoadFactory(config: ConfigService) {
	return () => config.loadConfig().then(() => console.log(`config resolved`, config));
}

@NgModule({
	declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
	imports: [
		BrowserAnimationsModule,
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		NgbModule,
		NgxSpinnerModule,
		ToastrModule.forRoot({
			timeOut: 10000,
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [ConfigService],
			},
		}),
		NgxPermissionsModule.forRoot(),
		PerfectScrollbarModule,
		AuthModule,
		InputMaskModule,
	],
	providers: [
		{provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: (config: ConfigService) => {
				return config.recaptchaSiteKey;
			},
			deps: [ConfigService],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appLoadFactory,
			deps: [ConfigService],
			multi: true,
		},
		WINDOW_PROVIDERS,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
